<template>
    <div>
        <v-dialog
            v-model="localDialogHistory"
            max-width="600"
            style="z-index: 10"
        >
            <v-card rounded="lg">
                <div
                    class="h-100 mt-3 mb-1 ml-3 mr-3 d-flex justify-content-between"
                >
                    <strong>Histórico de versões</strong>
                    <button
                        id="closeVersionModal"
                        class="btn-close"
                        @click="localDialogHistory = false"
                    ></button>
                </div>
                <v-divider class="mt-1 mb-2"></v-divider>

                <div v-if="dataHistory.length" class="list-container">
                    <div
                        v-for="(item, index) in dataHistory"
                        :key="index"
                        class="list-item"
                        @mouseover="item.showRestore = true"
                        @mouseleave="item.showRestore = false"
                    >
                        <div
                            class="item-wrapper"
                            :title="`Autor: ${item.author_name} Email: ${item.author_email}`"
                        >
                            <div class="left-info">
                                <div>
                                    <i
                                        v-if="item.type === 'snapshot'"
                                        class="ti ti-tag-starred"
                                    ></i>
                                    <i v-else class="ti ti-device-floppy"></i>
                                </div>
                                <span>{{ item.version_number }}</span>
                                <span v-if="item.type === 'snapshot'"> - </span>
                                <span
                                    v-if="!item.isEditing"
                                    class="text-ellipsis"
                                >
                                    {{ item.version_name || "" }}
                                </span>
                                <div v-else class="edit-container">
                                    <input
                                        v-model="item.newName"
                                        class="edit-input"
                                        @keyup.enter="confirmEdit(item)"
                                    />
                                    <button
                                        class="confirm-btn ti ti-check"
                                        @click.stop="confirmEdit(item)"
                                    ></button>
                                </div>
                            </div>
                            <div class="right-info">
                                <span>{{ formatDate(item.updated_at) }}</span>
                            </div>

                            <div class="dropdown">
                                <button
                                    class="btn btn-md tableOptionsDropdown m-0 p-1"
                                    type="button"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                >
                                    Opções
                                    <i class="ti ti-chevron-down m-0 p-0"></i>
                                </button>
                                <ul class="dropdown-menu p-2">
                                    <li>
                                        <a
                                            class="dropdown-item"
                                            role="button"
                                            @click="emitRestore(item._id)"
                                            >Restaurar</a
                                        >
                                    </li>
                                    <li v-if="item.type === 'snapshot'">
                                        <a
                                            class="dropdown-item"
                                            role="button"
                                            @click="startEditing(item)"
                                            >Alterar nome</a
                                        >
                                    </li>
                                    <li v-if="item.type === 'snapshot'">
                                        <a
                                            class="dropdown-item"
                                            role="button"
                                            @click="emitDelete(item._id)"
                                            >Deletar</a
                                        >
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else class="list-container centerContainer">
                    <div class="text-center">
                        <p class="text-medium-emphasis">Histórico Vazio</p>
                    </div>
                </div>

                <v-divider class="mt-2"></v-divider>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import moment from "moment-timezone"
import EventBus from "../services/event-bus"

export default {
    name: "FlowVersionModal",
    props: {
        dataHistory: {
            type: Array,
            required: true
        },
        showDialogHistory: {
            type: Boolean,
            required: true
        }
    },
    emits: ["restoreVersion", "updateName", "deleteSnapshot"],
    data() {
        return {
            localDialogHistory: this.showDialogHistory
        }
    },
    watch: {
        showDialogHistory() {
            this.localDialogHistory = true
        }
    },
    methods: {
        startEditing(item) {
            item.isEditing = true
            item.newName = item.version_name
        },
        confirmEdit(item) {
            const { isValid, message } = this.validateField(item.newName)
            if (!isValid) {
                item.newName = item.version_name
                EventBus.emit("message", {
                    type: "danger",
                    message
                })
                return
            }
            item.newName = item.newName.trim()
            item.version_name = item.newName

            this.$emit("updateName", item._id, item.newName)

            item.isEditing = false
        },
        emitRestore(versionId) {
            this.$emit("restoreVersion", versionId)
        },
        emitDelete(versionId) {
            const isConfirmed = confirm(
                "Deseja realmente deletar esse snapshot? Essa ação é irreversível!"
            )
            if (isConfirmed) {
                this.$emit("deleteSnapshot", versionId)
            }
        },
        formatDate(date) {
            moment.locale("pt-br")
            return moment(date).format("DD/MM/YYYY HH:mm:ss")
        },
        validateField(field) {
            if (
                field === undefined ||
                field === null ||
                field.trim().length <= 0
            ) {
                return { isValid: false, message: "Nome é obrigatório." }
            }
            if (field.trim().length > 60) {
                return {
                    isValid: false,
                    message: "Nome deve ter no máximo 60 caracteres."
                }
            }
            return { isValid: true, message: "" }
        }
    }
}
</script>

<style scoped>
.list-container {
    width: 100%;
    height: 300px;
    overflow-y: auto;
}

.list-item {
    padding: 2px 10px;
}

.centerContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.item-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 2px;
    position: relative;
    transition: all 0.5s ease;
    cursor: pointer;
}

.left-info {
    display: flex;
    gap: 2px;
    flex-grow: 1;
}

.right-info {
    text-align: right;
    margin-right: 25px;
}

.text-ellipsis {
    margin-left: 2px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 210px;
    display: inline-block;
}

.edit-container {
    display: flex;
    align-items: center;
    gap: 5px;
}

.edit-input {
    margin-bottom: 3px;
    height: 22px;
    padding: 1px 5px;
    font-size: 14px;
    width: 210px;
    border-radius: 3px;
    border: 1px solid #ccc;
}

.confirm-btn {
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 3px;
    padding: 0px 6px;
    height: 22px;
    cursor: pointer;
    font-size: 12px;
    transition: background-color 0.3s;
    margin-bottom: 3px;
}

.confirm-btn:hover {
    background-color: #45a049;
}
</style>
